<template>
  <v-container fluid fill-height>
    <v-layout column fill-height wrap>
      <v-card width="50vw" height="50vh" class="ma-auto">
        <v-row class="my-12">
          <p
            class="font-weight-black text-center fx-20 mx-auto"
            style="font-size:32px"
          >
            {{ $t('title.agency.login') }}
          </p>
        </v-row>
        <v-row>
          <p class="text-center fx-20 mx-auto" style="font-size:24px; color:red;">
            {{ $t('description.maintenance') }}
          </p>
        </v-row>
        <v-row>
          <p class="text-center fx-20 mx-auto" style="font-size:16px">
            {{ $t('description.maintenanceMessage') }}
          </p>
        </v-row>
      </v-card>
    </v-layout>
  </v-container>
</template>
<script>

export default {
  name: 'Maintenance',
  async mounted() {},
  methods: {},
};
</script>
